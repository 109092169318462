var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-wrapper",
    attrs: {
      "id": "admin-content"
    }
  }, [_c('Table', {
    attrs: {
      "data": _vm.tableData,
      "update-key": _vm.updatedUser
    },
    on: {
      "click": _vm.rowClicked,
      "reload": _vm.getUsersTableContent
    }
  }), _vm.userPanelOpen ? _c('UserSidepanel', {
    key: _vm.userUpdated,
    attrs: {
      "userId": _vm.clickedUser._id
    },
    on: {
      "overlayClicked": _vm.closeUserPanel,
      "editUser": _vm.editUser
    }
  }) : _vm._e(), _vm.editUserPanelOpen ? _c('AddUserSidepanel', {
    attrs: {
      "edit": true,
      "editable-user": _vm.userToEdit,
      "feedback-only": _vm.showFeedbackField,
      "achievements-only": _vm.showAchievementsField
    },
    on: {
      "closed": _vm.closeEditUserPanel
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }