<template>
  <div class="groups-container">
    <div
      v-for="[index, [id, group]] of Array.from(
        companyGroups.entries()
      ).entries()"
      class="group-card"
      :key="'group-card-' + index"
    >
      <div v-if="editingGroupId !== id" class="group-card-read">
        <div class="group-card-information">
          <p v-if="!group.isDefault" class="content-semibold">{{ group.name }}</p>
          <p class="content-semibold" v-if="group.isDefault">
            {{ group.name + ' (' + $t("Default") + ')' }}
          </p>
          <p class="gray-text" style="font-size: 14px">
            {{ findGroupAdmin(group) }}
          </p>
          <!--  <p class="content-small">
            {{ Object.values(group.users).length }} {{ $t("users") }}
          </p> -->
        </div>
        <BaseButton
          @itemSelected="
            (value) => groupCardDropdownItemSelected(id, group, value, index)
          "
          :display-key="'name'"
          :display-value="'value'"
          :custom-style="'cursor: pointer; height: 20px'"
          :icon-left="'dot_menu_gray_20_20.svg'"
          :items="groupCardDropdownItems(group)"
        />
      </div>
      <transition name="slide-fade">
        <div
          v-if="editingGroupId === id"
          class="group-card-edit"
          :ref="'group-card-' + id"
        >
          <div class="group-card-edit-fields-container">
            <TaskTextInput
              v-model="group.name"
              :placeholder="$t('GroupTitle')"
            />
            <p class="content-semibold">
              {{ $t("Users") }} ({{ Object.values(group.users).length }})
            </p>
          </div>
          <div class="group-card-table-container">
            <CustomTable
              :columns="groupTableColumns"
              :rows="Object.entries(group.users)"
              :draggable="true"
              @closed="closeGroup()"
              @updated="updateGroup(group)"
            />
          </div>
        </div>
      </transition>
      <DeleteModal
        :custom-message="deleteMessage"
        :removing="false"
        @canceled="isDeleteModalOpen = false"
        @approved="deleteGroup"
        v-if="isDeleteModalOpen"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/common/BaseButton";
import TaskTextInput from "@/components/CalendarView/TaskTextInput";
import CustomTable from "@/components/Admin/CustomTable";
import DeleteModal from "@/components/common/DeleteModal";

export default {
  name: "CompanyGroups",
  components: { CustomTable, TaskTextInput, BaseButton, DeleteModal },
  data() {
    return {
      editingGroupId: "",
      previousEditingGroupIndex: null,
      previousEditingGroupHeight: 0,
      isDeleteModalOpen: false,
      groupToDelete: null,
      addGroupOpen: false,
      companyGroups: null,
      addGroupName: "",
      deleteMessage: "",
      groupTableColumns: [
        {
          title: "Order",
          style: {
            minWidth: "100px",
            maxWidth: "100px",
            padding: "12px 16px",
            cursor: "pointer",
          },
          field: "ordering",
          type: "number",
          emptyValue: "-",
        },
        {
          title: "FullName",
          style: { width: "100%", padding: "12px 16px" },
          field: "name",
        },
       /*  {
          title: "Admin",
          style: { minWidth: "100px", padding: "12px 16px" },
          field: "isAdmin",
        }, */
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "groups"]),
  },
  async created() {
    await this.getGroups();
    await this.orderCompanyGroups()
  },
  methods: {
    ...mapActions(["getGroups"]),
    async updateGroup(group) {
      group._id = this.editingGroupId
      await axios.post("/api/admin/group-updated-users", group);
      this.editingGroupId = null;
      await this.getGroups();
      await this.orderCompanyGroups()
    },
    async orderCompanyGroups() {
      let sortedGroups = new Map(
        Object.entries(this.groups).sort(([, a], [, b]) => {
          const nameA = a.name?.toLowerCase();
          const nameB = b.name?.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
      );
      this.companyGroups = sortedGroups
    },
    groupCardDropdownItems(group) {
      if (group.isDefault || !this.user.isAdmin) {
        return [{ name: this.$t("Edit"), value: "EDIT" }];
      } else {
        return [
          { name: this.$t("Edit"), value: "EDIT" },
          { name: this.$t("SetDefault"), value: "SET_DEFAULT" },
          { name: this.$t("Delete"), value: "DELETE" },
        ];
      }
    },
    async groupCardDropdownItemSelected(groupId, group, selected, index) {
      switch (selected.value) {
        case "EDIT":
          this.editGroup(groupId, index);
          break;
        case "SET_DEFAULT":
          await axios.post("/api/group/default", {
            groupId: groupId,
            companyId: this.user.access.company._id,
          });
          await this.getGroups();
          break;
        case "DELETE":
          if (!this.user.isAdmin) {
            return;
          }
          this.groupToDelete = groupId;
          this.deleteMessage = `${this.$t("DeleteTheGroup")} ${
            group.name
          } ${this.$t("andMove")} ${
            Object.values(group.users).length
          } ${this.$t("usersToDefaultGroup")}?`;
          this.isDeleteModalOpen = true;
          break;
      }
    },
    async closeGroup() {
      this.editingGroupId = null;
      await this.getGroups()
      await this.orderCompanyGroups()
    },
    editGroup(groupId, index) {
      const pageTop = window.pageYOffset || document.documentElement.scrollTop;
      this.editingGroupId = groupId;
      // Scroll to the top of the group if needed
      this.$nextTick(() => {
        const groupRef = this.$refs["group-card-" + groupId];
        if (groupRef && groupRef[0]) {
          const groupElement = groupRef[0];
          const groupRect = groupElement.getBoundingClientRect();
          let y;
          // scroll to top of the element because top of element is under screen
          if (groupRect.top < 0) {
            y = pageTop + groupRect.top;
          }
          // if previously edited group is before new edit group
          if (parseInt(this.previousEditingGroupIndex) < index) {
            // if y exists then Take previous item height to consideration and scroll to top
            if (y) {
              y -= this.previousEditingGroupHeight;
            } else {
              // else element is on screen
              const groupCardHeight = 64;
              const newPageTop =
                pageTop - (this.previousEditingGroupHeight - groupCardHeight);
              if (newPageTop > 0) {
                y = newPageTop;
              }
            }
          }
          if (y !== undefined) {
            window.scrollTo({ top: y, behavior: "smooth" });
          }
          this.previousEditingGroupIndex = index;
          this.previousEditingGroupHeight = groupRect.height;
        }
      });
    },
    async deleteGroup() {
      try {
        await axios.post("/api/delete-group", {
          groupId: this.groupToDelete,
          companyId: this.user.access.company._id,
        });
        this.toastSuccess(this.$t("Deleted"));
        this.isDeleteModalOpen = false;
        this.groupToDelete = null;
        await this.getGroups();
      } catch (e) {
        this.toastError("ErrorDeleting");
      }
    },
    findGroupAdmin(group) {
      let adminInGroup = []
      Object.values(group.users).forEach((user) => {
        if (user.isAdmin) {
          adminInGroup.push(user.name)
        }
      })
      if (adminInGroup.length) {
        return adminInGroup.join(", ")
      }
      else {return "-"}
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.groups-container {
  display: flex;
  flex-direction: column;
  width: 616px;
}

.group-card {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $grey500;
  border-top: 1px solid $grey500;
  border-right: 1px solid $grey500;
}

.group-card:first-of-type {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.group-card:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom: 1px solid $grey500;
}

.group-card-information {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.group-card-read {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  max-height: 100px;
}

.group-card-edit {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.group-card-users-container {
  display: flex;
  flex-direction: column;
}

.group-user-card {
  display: flex;
  gap: 12px;
  padding: 6px 12px;
  height: 32px;
  background-color: #fff;
}

.group-user-card .order-number-button {
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: inherit;
}

.group-card-edit-fields-container {
  padding: 12px;
}

.group-card-table-container {
  background-color: $grey;
  padding: 12px;
}

.handle {
  cursor: grab;
}

.grabbing * {
  cursor: grabbing !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.button-container {
  display: flex;
  padding: 12px;
  margin-top: 24px;
}
::v-deep .button-dropdown-container {
  overflow-y: hidden;
}
</style>